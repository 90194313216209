<template>
  <div class="refund-item">
    <v-list-item class="py-3">
      <v-row class="w-100 my-2 my-sm-0" align="center" justify="space-between">
        <v-col cols="8" sm="10" md="8" class="pa-0">
          <div class="d-flex">
            <v-list-item-avatar
              width="100"
              height="100"
              rounded="0"
              class="my-0"
            >
              <img
                :src="item.product.mediaURL"
                :alt="item.product.name"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content class="default--text pa-0 pa-sm-2">
              <div class="text-body-2">
                {{ item.product.name }}
              </div>
              <v-list-item-subtitle
                class="font-weight-bold text-uppercase text-body-2"
              >
                {{ item.product.shortDescr }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-uppercase">
                {{ item.product.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </v-col>
        <v-col cols="4" sm="2" md="2">
          <div v-if="readOnly">
            <div v-if="item.product.productInfos.TIPOLOGIA == 'Pezzo'">
              {{ this.quantity }} {{ item.product.priceUnitDisplay }}
            </div>
            <div v-else>
              {{ refoundWeightMaxFormatted }}&nbsp;{{
                item.product.priceUnitDisplay
              }}
            </div>
          </div>
          <div v-else-if="item.weight == 0 && item.quantity == 0">
            {{ $t("refund.notPossible") }}
          </div>
          <template v-else-if="!readOnly">
            <div
              v-if="
                item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                  item.quantity >= 0
              "
            >
              <!-- prodotti al pezzo -->
              pezzo<v-select
                v-model="item.newQuantity"
                :items="rangeArray"
                dense
                label="Quantità"
                solo
                @change="emitNewQuantity"
                hide-details
              ></v-select>
            </div>
            <div
              v-else-if="
                (item.product.productInfos.TIPOLOGIA == 'Sfuso' ||
                  item.product.productInfos.TIPOLOGIA == 'Confezione') &&
                  refoundWeightMaxFormatted != '0'
              "
            >
              <!-- prodotti confezione e sfusi -->
              <div class="font-weight-600 text-end">
                Qta max: {{ refoundWeightMaxFormatted }}&nbsp;{{
                  item.product.priceUnitDisplay
                }}
              </div>
              <div class="qty-input-unpackage-product d-flex flex-nowrap">
                <span class="mt-2">
                  {{ item.product.priceUnitDisplay }}:&nbsp;
                </span>
                <v-text-field
                  v-model="newWeight"
                  label="Quantità"
                  solo
                  dense
                  :rules="[isFloatingNumberRule()]"
                  @change="changeWeight"
                  @keyup="changeWeight"
                ></v-text-field>
              </div>
            </div>
          </template>
        </v-col>
        <v-col cols="12">
          <div class="refund-options d-flex flex-wrap align-center">
            <div class="font-weight-600">{{ $t("refund.refundReason") }}</div>
            <div v-if="readOnly">
              {{ itemRefundReasons[item.wishlistItemInfo.REFUND_CAUSE] }}
            </div>
            <div v-else>
              <v-select
                v-model="item.wishlistItemInfo.REFUND_CAUSE"
                :items="refundReasonOptions"
                item-text="reason"
                item-value="key"
                dense
                label=""
                solo
                hide-details
              ></v-select>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="py-0">
          <div class="d-flex align-center justify-end">
            <div v-if="imageError" class="error--text mr-2">
              {{ imageError }}
            </div>
            <div
              class="preview-image mr-3 d-flex align-center"
              v-if="item.wishlistItemInfo['IMAGE']"
            >
              <img :src="item.wishlistItemInfo['IMAGE']" alt="Immagine reso" />
              <v-btn
                fab
                x-small
                @click="removeImage"
                :aria-label="$t('common.close')"
                v-if="!readOnly"
              >
                <v-icon class="text--primary">
                  $close
                </v-icon>
              </v-btn>
            </div>
            <div v-if="!readOnly">
              <label class="file-input-button v-btn">
                <span>{{
                  $t(
                    item.wishlistItemInfo["IMAGE"]
                      ? "refund.changeImg"
                      : "refund.addImg"
                  )
                }}</span>
                <v-file-input
                  ref="fileInput"
                  @change="fileNameChanged"
                  accept="image/jpeg,image/gif,image/png,application/pdf"
                  v-model="selectedImg"
                ></v-file-input>
              </label>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-list-item>
  </div>
</template>
<style lang="scss">
.refund-item {
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-base);
    border-radius: 0;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    min-width: 85px;
    border: 1px solid var(--v-default-base);
  }
  .qty-input-unpackage-product {
    width: 120px;
  }
  .v-icon.v-icon {
    font-size: 15px;
    color: var(--v-default-base) !important;
  }
  .v-select .v-select__selection--comma {
    color: var(--v-default-base) !important;
    font-weight: 600;
  }
  .refund-options {
    column-gap: 10px;
    row-gap: 5px;
    .v-select {
      width: 275px;
      font-size: 14px;
    }
  }
  .file-input-button {
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    background-color: $white;
    color: var(--v-primary-base);
    border: thin solid var(--v-primary-base);
    border-radius: 4px;
    cursor: pointer;
  }

  .file-input-button .v-input {
    display: none;
  }
  .preview-image {
    width: 100px;
    border: 1px solid var(--v-primary-base);
    border-radius: 8px;
    padding: 5px;
    position: relative;
    img {
      width: 100%;
    }
    .v-btn--fab {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

import { isFloatingNumber } from "~/validator/validationRules";

export default {
  name: "RefundItem",
  mixins: [productMixin],
  props: {
    item: { type: Object, required: true },
    readOnly: { type: Boolean, default: true }
  },
  data() {
    return {
      newWeight: 0,
      selectedImg: null,
      itemRefundReasons: {
        1: "Ci ho ripensato",
        2: "Non sono soddisfatta/o",
        3: "Prodotto non idoneo",
        4: "Errore di prezzo/Sconto non applicato",
        5: "Prodotto non ricevuto"
      },
      imageError: null,
      isFloatingNumberRule: isFloatingNumber
    };
  },
  computed: {
    refoundWeightMaxFormatted() {
      return this.item.weight
        ? this.item.weight.toString().replace(".", ",")
        : "0";
    },
    rangeArray() {
      const max = parseInt(this.item.wishlistItemInfo.MAX_QUANTITY);
      return Array.from({ length: max + 1 }, (_, index) => index);
    },
    refundReasonOptions() {
      return Object.entries(this.itemRefundReasons).map(([key, reason]) => ({
        key: key,
        reason: reason
      }));
    }
  },
  methods: {
    changeWeight() {
      this.emitNewWeight();
    },
    emitNewWeight() {
      let tempWeight = this.newWeight.replace(",", ".");
      if (parseFloat(tempWeight)) {
        if (tempWeight > this.item.weight) {
          this.newWeight = this.item.weight.toString().replace(".", ",");
        }
        this.$emit("updateNewWeight", parseFloat(tempWeight));
      } else {
        this.$emit("updateNewWeight", 0);
      }
    },
    emitNewQuantity() {
      this.$emit("updateNewQuantity", this.item.newQuantity);
    },
    async fileNameChanged() {
      if (this.selectedImg) {
        if (this.selectedImg.size / 1024 > 10240) {
          this.imageError = "L'allegato non deve superare i 10MB";
        } else {
          this.imageError = null;
          let docBase64 = await this.toBase64(this.selectedImg);
          this.updateItemImg(docBase64);
        }
      } else {
        this.updateItemImg(null);
      }
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    removeImage() {
      this.updateItemImg(null);
    },
    updateItemImg(img) {
      this.$emit("updateItemImg", img);
    }
  },
  mounted() {}
};
</script>
