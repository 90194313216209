import axios from "~/plugins/axios";
export default {
  listRefunds(orderId) {
    let url = `/ebsn/api/refund/list`;
    var params = {
      order_id: orderId
    };
    return axios.get(url, { params }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return null;
      }
    });
  },
  viewRefund(orderRefundId) {
    let url = `/ebsn/api/refund/view`;
    var params = { list_id: orderRefundId };
    return axios.get(url, { params }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return null;
      }
    });
  },
  addNewRefund(orderId, orderItemIds) {
    const params = new URLSearchParams();
    params.append("order_id", orderId);
    if (typeof orderItemIds == "object") {
      orderItemIds.forEach(orderItemId => {
        params.append("order_item_id", orderItemId);
      });
    } else if (orderItemIds) {
      params.append("order_item_id", orderItemIds);
    }
    let url = `/ebsn/api/refund/add-new?${params.toString()}`;
    return axios.post(url).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return null;
      }
    });
  },
  uploadRefund(refund) {
    let url = `/ebsn/api/refund/upload`;
    return axios.post(url, refund).then(data => {
      if (data && data.data) {
        return data.data;
      } else {
        return null;
      }
    });
  },
  substitute(orderId, orderItemId, accept) {
    let url = `/ebsn/api/order/substitute?action=${
      accept ? "accept" : "remove"
    }&order_id=${orderId}&order_item_id=${orderItemId}`;

    return axios.post(url).then(data => {
      if (data && data.data) {
        return data.data;
      } else {
        return null;
      }
    });
  },
  saveSubstitute(orderId) {
    let url = `/ebsn/api/order/save-substitutes-reopen-order?order_id=${orderId}`;
    return axios.post(url).then(data => {
      if (data && data.data) {
        return data.data;
      } else {
        return null;
      }
    });
  }
};
